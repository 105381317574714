<template>
  <section id="error-page">
    <h1>Oops, looks like something went wrong!</h1>
    <br />
    <router-link to="/" id="button">
      Back to home
    </router-link>
  </section>
</template>

<script>
export default {
  name: 'SubmissionFail'
}
</script>
